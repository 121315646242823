@import "vars";

:root {
	--color-primary: $colour-primary;
	--color-secondary: $colour-secondary;
	--color-tertiary: $colour-tertiary;
	--color-white: $colour-white;
	--color-black: $colour-black;
	--color-blue: $colour-blue;
	--color-blue-grey: $colour-blue-grey;
	--color-light-blue-grey: $colour-light-blue-grey;
	--color-light-grey: $colour-light-grey;
	--color-lightest-grey: $colour-lightest-grey;
	--color-aqua: $colour-aqua;
	--color-yellow: $colour-yellow;
	--color-success: $colour-success;
	--color-error: $colour-error;
	--color-warning: $colour-yellow;

	--filter-primary: $filter-primary;
	--filter-secondary: $filter-secondary;
	--filter-tertiary: $filter-tertiary;
	--filter-white: $filter-white;
	--filter-black: $filter-black;
	--filter-blue: $filter-blue;
	--filter-blue-grey: $filter-blue-grey;
	--filter-light-blue-grey: $filter-light-blue-grey;
	--filter-light-grey: $filter-light-grey;
	--filter-lightest-grey: $filter-lightest-grey;
	--filter-aqua: $filter-aqua;
	--filter-yellow: $filter-yellow;
	--filter-success: $filter-success;
	--filter-error: $filter-error;
	--filter-warning: $filter-yellow;
}
